import React from "react";
import { Icon } from "@virtualcapital/utogi-ui-library";

import { Link } from "react-router-dom";

import "./mixAndMatch.scss";

const mixAndMatch = () => {
  return (
    <section className="get-started-section">
      <div className="animated-icon">
        <Icon icon="envelop" />
      </div>
      <div className="animated-icon">
        <Icon icon="people" />
      </div>
      <div className="animated-icon">
        <Icon icon="marketplace" />
      </div>
      <div className="animated-icon">
        <Icon icon="marketing" />
      </div>
      <div className="animated-icon">
        <Icon icon="compliance" />
      </div>
      <div className="container featured">
        <span>Mix &amp; Match</span>
        <p>
          Use part of the Utogi Suite, intergated with your current providers,
          <br />
          or migrate to our entire Suite for a seamless experience.
        </p>
        <Link to="/contact-us">Request a Demo</Link>
      </div>
      <ul className="client-list">
        <li>
          <img
            src={require("assets/images/home/clients/facebook.svg")}
            alt="iconic"
          />
        </li>
        <li>
          <img
            src={require("assets/images/home/clients/realestate.png")}
            alt="travels"
          />
        </li>
        <li>
          <img
            src={require("assets/images/home/clients/microsoft.svg")}
            alt="mockup"
          />
        </li>
        <li>
          <img
            src={require("assets/images/home/clients/google.svg")}
            alt="natural"
          />
        </li>
        <li>
          <img
            src={require("assets/images/home/clients/trademe.png")}
            alt="the-backyard"
          />
        </li>
      </ul>
      <div className="get-started-block container">
        <div className="get-started-block-container">
          <img
            src={require("assets/images/home/introducing-utogi.svg")}
            alt="introducing-utogi"
          />
          <div className="get-started-block-container-inner">
            <span>App Market Place &amp; API Integrations</span>
            <p>
              Think we&apos;re missing something? Build your own internal apps
              for your team, or sell them to our customer base.
            </p>
            <p style={{ textAlign: "right" }}>
              <Link to="/contact-us">
                Register Interest
                <Icon icon="chevron-right" />
              </Link>
            </p>
          </div>
        </div>
        <div className="get-started-block-container">
          <img
            src={require("assets/images/home/explore-the-docs.svg")}
            alt="explore-the-docs"
          />
          <div className="get-started-block-container-inner">
            <span>Support</span>
            <p>
              We know software can be hard sometimes. That's why we have click
              through walk-through to help you every step of the way.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default mixAndMatch;
