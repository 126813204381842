import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Loader from "components/loader";

import HomePage from "pages/home";
import ContactUs from "pages/contactUs";

import GraphQLProvider from "graphql/client";

import "./App.scss";

function App() {
  return (
    <GraphQLProvider>
      <Router>
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route exact path="/">
              <HomePage />
            </Route>
            <Route exact path="/contact-us">
              <ContactUs />
            </Route>
          </Switch>
        </Suspense>
      </Router>
    </GraphQLProvider>
  );
}

export default App;
